import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Add = () => {
  const [book, setBook] = useState({
    title: "",
    description: "",
    price: "",
    cover: "",
  })

  const navigate = useNavigate();

  const handleChange = (e) => {
    setBook(prev => ({...prev, [e.target.name]: e.target.value}));
  }
  
  const handleClick = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${process.env.REACT_APP_API}/books`, book);
      navigate("/")
    } catch(err) {
      console.log(err);
    }
  }


  return (
    <div className="form">
      <h1>Add New Book</h1>
      <input type='text' onChange={handleChange} placeholder='title' name="title" /> <br />
      <input type='text' onChange={handleChange} placeholder='description' name="description" /> <br />
      <input type='number' onChange={handleChange} placeholder='price' name="price" /> <br />
      <input type='text' onChange={handleChange} placeholder='cover' name="cover" /> <br />
      <button className='formButton' onClick={handleClick}>Add</button>
    </div>
  )
}

export default Add