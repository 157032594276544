import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'

const Update = () => {
  const [book, setBook] = useState({
    title: "",
    description: "",
    price: "",
    cover: "",
  })   

  const navigate = useNavigate();
  const location = useLocation();
  const bookId = location.pathname.split("/")[2];

  useEffect(() => {
    const fetchAllBooks = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API}/books/${bookId}`);
        setBook({
          title: res.data[0].title,
          description: res.data[0].description,
          price: res.data[0].price,
          cover: res.data[0].cover,
        });
      } catch(err) {
        console.log(err);
      }
    }
    fetchAllBooks();
  }, [])

  const handleChange = (e) => {
    setBook(prev => ({...prev, [e.target.name]: e.target.value}));
  }
  
  const handleClick = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`${process.env.REACT_APP_API}/books/${bookId}`, book);
      navigate("/")
    } catch(err) {
      console.log(err);
    }
  }


  return (
    <div className="form">
      <h1>Update a Book</h1>
      <input type='text' onChange={handleChange} placeholder='title' name="title" value={book.title} /> <br />
      <input type='text' onChange={handleChange} placeholder='description' name="description" value={book.description} /> <br />
      <input type='number' onChange={handleChange} placeholder='price' name="price" value={book.price} /> <br />
      <input type='text' onChange={handleChange} placeholder='cover' name="cover" value={book.cover} /> <br />
      <button className='formButton' onClick={handleClick}>Update</button>
    </div>
  )
}

export default Update